<template>
  <div class="page flex-col">
    <div class="box_20 flex-col">
	  
      <div class="box_2 flex-col">
        <div class="box_3 flex-row justify-between">
          <div class="text-group_8 flex-col">
            <span class="text_4">清风阜康</span>
            <span class="text_5">中共阜康市纪律检查委员会</span>
          </div>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc1105270c214940e6d12620ee5d1ca6857d6481394dcf2b24fa6058d86eda8f6"
          />
        </div>
      </div>
	  <van-search
	    v-model="searchZc"
	    :show-action="false"
	    placeholder="请输入政策关键词"
	    @search="searchClick"
	  />
	  <img
	    class="image_2"
	    referrerpolicy="no-referrer"
	    src="../assets/fk1.png"
		 style="height: 120px;width: 100%;"
	  />
	  <!-- <van-swipe :autoplay="3000" lazy-render  style="height: 120px;">
	    <van-swipe-item v-for="image in images" :key="image">
	      <img :src="image" style="width:100%;height:100%;object-fit: contain;"/>
	    </van-swipe-item>
	  </van-swipe> -->
      <!-- <img
        class="image_3"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3e0770d73caa4baa17866a3c26d172195803d4a3ad13fb72e570e4511febf9b4"
      /> -->
      <span class="text_6">公开监督</span>
      <div class="group_2 flex-row">
        <div class="group_3 flex-col" @click="toPage(1)">
          <div class="image-wrapper_2 flex-col">
            <img
              class="label_4"
              style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon2.png"
            />
			<!-- https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfed2a3c590402ebd251b68260f9780b37f70f9dfd68bc7e768a96584362678ef -->
          </div>
          <span class="text_8">小微权力</span>
        </div>
		<div class="group_5 flex-col" @click="toPage(2)">
		  <div class="image-wrapper_3 flex-col">
		    <img
		      class="label_5"
			  style="width:45px;height:45px;"
		      referrerpolicy="no-referrer"
		      src="../assets/home/home_icon3.png"
		    />
		  </div>
		  <span class="text_9">三资监管</span>
		</div>
        <div class="group_4 flex-col" @click="toPage(0)">
          <div class="image-wrapper_1 flex-col">
            <img
              class="label_3"
              style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon1.png"
            />
			<!-- https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge59da79cdbcd7a9efc258294ce1c52af0212d8c0aa830713f36e5522a8451e88 -->
          </div>
          <span class="text_7">惠民惠农资金</span>
        </div>
        <div class="image-text_4 flex-col" @click="toPage(3)">
          <div class="image-wrapper_4 flex-col">
            <img
              class="label_6"
			  style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon4.png"
            />
          </div>
          <span class="text-group_2">营商环境</span>
        </div>
      </div>
      <div class="group_6 flex-row justify-between">
        <a rel="nofollow" href="https://xinjiang.12388.gov.cn/changji/fukangshi/m/">
			<div class="box_9 flex-row">
			  <div class="text-group_9 flex-col">
				<span class="text_10">信访举报</span>
				<span class="text_11">12388</span>
			  </div>
			</div>
		</a>
		<a rel="nofollow" href="tel:0994-3220307">
			<div class="box_10 flex-row">
			  <div class="text-group_10 flex-col">
				<span class="text_12">信访举报电话</span>
				<span class="text_11">0994-3220307</span>
			  </div>
			</div>
		</a>
      </div>
      <span class="text_14">监督投诉</span>
      <div class="group_7 flex-row">
        <div class="group_8 flex-col" @click="toPage(6)">
          <div class="image-wrapper_5 flex-col">
            <img
              class="label_7"
			  style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon5.png"
            />
          </div>
          <span class="text_15">码上巡</span>
        </div>
        <div class="image-text_5 flex-col" @click="toPage(7)">
          <div class="image-wrapper_6 flex-col">
            <img
              class="label_8"
			  style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon6.png"
            />
          </div>
          <span class="text-group_5">监督随手拍</span>
        </div>
        <div class="group_9 flex-col" @click="toPage(8)">
          <img
            class="label_9"
			style="width:45px;height:45px;"
            referrerpolicy="no-referrer"
            src="../assets/home/home_icon7.png"
          />
          <span class="text_16">投诉举报</span>
        </div>
        <div class="image-text_6 flex-col" @click="toPage(9)">
          <div class="image-wrapper_7 flex-col">
            <img
              class="label_10"
			  style="width:45px;height:45px;"
              referrerpolicy="no-referrer"
              src="../assets/home/home_icon8.png"
            />
          </div>
          <span class="text-group_6">投诉办理查询</span>
        </div>
      </div>
      <div class="group_10 flex-row justify-between">
        <div class="text-group_11 flex-col">
          <span class="text_17">最新政策</span>
        </div>
		<div class="more">
		  <router-link :to="{ name: 'zclist' }"
		    >{{ $t('public.more') }}&gt;&gt;
		  </router-link>
		</div>
      </div>
	  <!-- 政策 -->
	  <!-- <van-list
	    v-model="loading"
	    :finished="finished"
	    :finished-text="$t('public.noMore')"
	    @load="onLoad"
	  > -->
	  <div class="scroll-container">
		  <div class="scroll-list">
			  <div v-for="item in zhengceNewsList" :key="item.id">
			    <div class="item-content">
			      <div class="item-inner">
			        <div class="item-title">
			          <router-link
			            :to="{
			              name: 'zcdetail',
			              query: { id: item.id, isSource: 2 },
			            }"
			            >{{ item.name }}
			          </router-link>
			        </div>
			        <div class="item-after">{{ item.time.slice(5) }}</div>
			      </div>
			    </div>
			  </div>
		  </div>
	  </div>
	    
	  <!-- </van-list> -->
    </div>
  </div>
</template>
<script>
	import '@/assets/css/index.css';
	import '@/assets/css/common.css';
	// 政策
	import BASE from "@/utils/base";
	export default {
		data() {
			return {
				zhengceNewsList: [],
				searchZc: "",
				pages: 0,
				pageNo: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				param: {},
				images: [
					 './assets/fk1.png',
				]
			};
		},
		async activated() {
		  // this.pageNo = 1;
		  
		},
		mounted() {
			this.onLoad();
		},
		methods: {
			async searchClick() {
			  this.finished = false;
			  this.loading = true;
			  this.zhengceNewsList = [];
			  this.pageNo = 1;
			  if (this.searchText != "") {
			    this.param = { name: "*" + this.searchZc + "*" };
			  } else {
			    this.param = {};
			  }
			  this.onLoad();
			},
			onLoad() {
			  const params = {
			    type: "",
			    remark: "",
			    pageNo: 1,
			    pageSize: 10,
			    ...this.param,
			  };
			  const url = "zxSupervisePolicy/list";
			
			  this.axios
			    .get(BASE.genUrl(url, params))
			    .then((res) => {
			      let data = res.data;
			      // 加载状态结束
			      this.loading = false;
			
			      if (data.code !== 200) this.finished = true;
			
			      if (data.result) {
			        this.zhengceNewsList = [
			          // ...this.zhengceNewsList,
			          ...data.result.records,
			        ];
			        // this.pageNo += 1;
			        // 数据全部加载完成
			        // if (data.result.records.length < this.pageSize) {
			        //   this.finished = true;
			        // }
					this.finished = true;
			      }
			    })
			    .catch(() => {
			      this.loading = false;
			      this.finished = true;
			    });
			},
			toPage(i) {
				let path = '';
				switch (i) {
					case 0: path = '/';break;
					case 1: path = '/supervise_index';break;
					case 2: path = '/capitalManage';break;///capitalManage
					case 3: path = '/business_development_index';break;
					case 4: path = '';break;
					case 5: path = '';break;
					case 6: path = '/patrolNotice';break;
					case 7: path = '/casually_index';break;
					case 8: path = '/home_report';break;
					case 9: path = '/tousu';break;
					default: break;
				}
				if(path == "/patrolNotice"){
					this.$router.push({ name: 'patrolNotice', params: { id: 1 }})
				}else{
					this.$router.push(path)
				}
				
			}
		},
	};
</script>
<style scoped>
	.page {
		height: 100vh;
		overflow: hidden !important;
	}
	.box_20{
		overflow-y: auto;
		height: 100%;
	}
	.item-content {
	  padding: 4px 0;
	}
	
	.item-inner {
	  display: flex;
	  flex-direction: row;
	}
	
	.item-title {
	  padding-right: 0.1rem;
	  font-size: 13px;
	  width: 80%;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  white-space: nowrap;
	  margin-left: 16px;
	  text-align: left;
	  color: #323232;
	  font-weight: normal;
	  line-height: 18px;
	}
	
	.item-after {
	  width: 15%;
	  color: #909090;
	}
	
	.scroll-container {
	  /* width: 375px; */
	  height: 120px; 
	  overflow: hidden;
	  /* background-color: #fff; */
	  /* border: 1px solid #ccc; */
	  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
	  position: relative;
	}
	.scroll-list {
	  width: 100%;
	  list-style: none;
	  margin: 0;
	  padding: 0;
	  position: absolute;
	  animation: scrollUp 20s ease-in-out infinite;
	}
	@keyframes scrollUp {
	  0% {
	    top: 0;
	  }
	  100% {
	    top: -100%; /* 根据内容调整 */
	  }
	}
</style>
